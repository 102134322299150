export const PrivateScheduleKey = 'isPrivateSchedule';

export enum Stage {
  DEV = 'dev',
  BETA = 'beta',
  PROD = 'prod',
}

export enum CountryFullName {
  UK = 'United Kingdom',
  US = 'United States',
  MX = 'Mexico',
  CA = 'Canada',
  IN = 'India',
  IN_CS = 'India',
  IE = 'Ireland',
  JP = 'Japan',
  PH = 'Philippines',
  ZA = 'South Africa',
  DEVO = 'development',
}

export enum GeoUnit {
  KM = 'km',
  MILE = 'mi',
}

export enum Locale {
  UK = 'en-GB',
  US = 'en-US',
  ES_US = 'es-US',
  ES_MX = 'es-MX',
  EN_CA = 'en-CA',
  FR_CA = 'fr-CA',
  EN_IN = 'en-IN',
  JA_JP = 'ja-JP',
  EN_PH = 'en-US',
  EN_ZA = 'en-ZA',
  EN_IE = 'en-GB',
}

export enum HVHCareersCountryCode {
  AE = 'AE', // United Arab Emirates
  BR = 'BR', // Brazil
  CA = 'CA', // Canada
  CO = 'CO', // Colombia
  CR = 'CR', // Costa Rica
  DE = 'DE', // Germany
  DEVO = 'DEVO', // Development
  EG = 'EG', // Egypt Green Badge
  EG_CS = 'EG_CS', // Egypt CareerSite
  IE = 'IE', // Ireland
  IN = 'IN', // India Green Badge
  IN_CS = 'IN_CS', // India CareerSite
  JO = 'JO', // Jordan
  JP = 'JP', // Japan
  MX = 'MX', // Mexico
  PH = 'PH', // Phillippines
  SA = 'SA', // Kingdom of Saudi Arabia
  UK = 'UK', // United Kingdom
  US = 'US', // United States
  ZA = 'ZA', // South Africa
}

export enum CurrencyCode {
  GBP = 'GBP',
  USD = 'USD',
  CAD = 'CAD',
  MXN = 'MXN',
  JPY = 'JPY',
  PHP = 'PHP',
  INR = 'INR',
  EUR = 'EUR',
  ZAR = 'ZAR',
}

export const CurrencyCodeMap: Record<string, string> = {
  [CurrencyCode.GBP]: '£',
  [CurrencyCode.USD]: '$',
  [CurrencyCode.CAD]: '$',
  [CurrencyCode.MXN]: 'MXN$',
  [CurrencyCode.JPY]: '¥',
  [CurrencyCode.PHP]: '₱',
  [CurrencyCode.INR]: '₹',
  [CurrencyCode.EUR]: '€',
  [CurrencyCode.ZAR]: 'R',
};

export enum BorderEnum {
  SOLID = 'solid',
}

export enum JobTypeRawDataEnum {
  Part = 'part',
  PartTimeDash = 'part-time',
  PartTimeUnderScore = 'part_time',

  Full = 'full',
  FullTimeDash = 'full-time',
  FullTimeUnderScore = 'full_time',

  Flex = 'flex',
  FlexTimeUnderScore = 'flex_time',

  ReduceTimeUnderScore = 'reduced_time',
}

export enum EmploymentTypeRawDataEnum {
  Temporary = 'Temporary',
  Seasonal = 'Seasonal',
}

export enum VirtualLocationRawDataEnum {
  Yes = 'Yes',
}
export const NotApplicable = 'N/A';

export const RemoteLocation = 'Remote location';

export const MaxDistance = 2147483647;

// Location resolver will error if the character limit exceeds 200 characters
export const LOCATION_MAX_CHAR_LIMIT = 200;
export const JOB_CARDS_LOAD_MORE_REQUEST_LIMIT = 25;
export let JOB_CARDS_REQUEST_LIMIT = 100;
export const SCHEDULE_SHIFT_SELECTION_LIMIT = 3;

export const setJobCardLimit = (limit: number) => {
  JOB_CARDS_REQUEST_LIMIT = limit;
};

export const homePageIngressParamAddress = 'locationSearch';
export const homePageIngressParamFilter = 'filter';
export enum MetricNames {
  ApiQueryGeoInfoByAddress = 'Api.QueryGeoInfoByAddress',
  ApiSearchJobCardsByLocation = 'Api.SearchJobCardsByLocation',
  ApiQueryLatestJobAddress = 'Api.QueryLatestJobAddress',
  ApiGetJobDetail = 'Api.GetJobDetail',
  ApiSearchScheduleCards = 'Api.SearchScheduleCards',
  SubSectionFilterScreenLoad = 'SubSection.FilterScreenLoad',
  SubSectionJobSearchLoad = 'SubSection.JobSearchLoad',
  ScreenJobDetailScreenLoad = 'Screen.JobDetailLoad',
  ScreenShiftSelectionScreenLoad = 'Screen.ShiftSelectionLoad',
  APIResponseJobsCount = 'APIResponse.JobsCount',
  UserActionKeywordSearch = 'Action.KeywordSearch',
  UserActionLocationSearch = 'Action.LocationSearch',
  UserActionJobCardClick = 'Action.JobCardClick',
  UserActionFilterScreenClose = 'Action.FilterScreenClose',
  UserActionResetAllFilterClick = 'Action.ResetAllFilterClick',
  UserActionShowFilterResultClick = 'Action.ShowFilterResultClick',
  UserActionFilterSelected = 'Action.Sel', // Sel = Selected
  UserActionFilterUnSelected = 'Action.UnSel', // UnSel = UnSelected
  UserActionScheduleSelectionScreenClose = 'Action.ScheduleSelectionScreenClose',
  UserActionSalesforceJobApplyClick = 'Action.SalesforceJobApplyClick',
  UserActionRedirectToShiftSelectionClick = 'Action.Redirect.ShiftSelectionClick',
  UserActionSelectShiftClick = 'Action.SelectShiftClick',
  UserActionApplyClick = 'Action.ApplyClick',
}

export enum FilterTitle {
  SortBy = 'Sort by',
  MaximumCommuteDistance = 'Maximum commute distance',
  HoursPerWeek = 'Hours per week',
  TimeOfShift = 'Time of shift',
  TimeOfWeek = 'Time of week',
  LengthOfEmployment = 'Length of employment',
  StartDate = 'Start date',
  Department = 'Department',
}

export const CareerSiteCMPID = 'AMOZSG808H11';
