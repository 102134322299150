import React, {PropsWithChildren, useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {useIsReverseProxyEnabled} from '../features';
import {setFeatureFlag} from '../slices/featureFlag.slice';

const FeatureFlagProvider = (props: PropsWithChildren<{}>) => {
  const dispatch = useDispatch();
  const isReverseProxyEnabled = useIsReverseProxyEnabled();

  useEffect(() => {
    dispatch(setFeatureFlag({isReverseProxyEnabled})); // Pass as object
  }, [dispatch, isReverseProxyEnabled]);

  return <>{props.children}</>;
};

export default FeatureFlagProvider;
